@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

* {
  box-sizing: border-box;
  font-family: 'IBM Plex Sans', 'Helvetiva Nue', Arial, sans-serif;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover,
a:active,
a:focus,
a:visited {
  color: inherit;
}

.width_container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

h1 {
  font-size: 1.375rem;
  font-weight: 400;
  display: inline-block;
}

p {
  line-height: 1.6;
}

body {
  background: #f3f3f4;
  font-family: 'IBM Plex Sans';
}
